import * as React from 'react';
import type { GatsbyBrowser, GatsbySSR } from 'gatsby';
import './src/styles/global.css';

import '@fontsource/inter/latin-400.css';
import '@fontsource/inter/latin-300.css';
import '@fontsource/inter/latin-500.css';
import '@fontsource/inter/latin-600.css';
import '@fontsource/inter/latin-700.css';
import '@fontsource/inter/latin-900.css';

import '@fontsource/montserrat/latin-400.css';
import '@fontsource/montserrat/latin-300.css';
import '@fontsource/montserrat/latin-500.css';
import '@fontsource/montserrat/latin-600.css';
import '@fontsource/montserrat/latin-700.css';

function hotjarLoad(h, o, t, j, a, r) {
  h.hj =
    h.hj ||
    function () {
      (h.hj.q = h.hj.q || []).push(arguments);
    };
  h._hjSettings = { hjid: 3886489, hjsv: 6 };
  a = o.getElementsByTagName('head')[0];
  r = o.createElement('script');
  r.async = 1;
  r.id = 'hotjar';
  r.src = t + h._hjSettings.hjid + j + h._hjSettings.hjsv;
  a.appendChild(r);
}

function googleTagManagerLoad(w, d, s, l, i) {
  w[l] = w[l] || [];
  w[l].push({ 'gtm.start': new Date().getTime(), event: 'gtm.js' });
  const f = d.getElementsByTagName(s)[0],
    j = d.createElement(s),
    dl = l != 'dataLayer' ? '&l=' + l : '';
  j.async = true;
  j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl;
  f.parentNode.insertBefore(j, f);
}

const loadAnalyticsScripts = () => {
  hotjarLoad(
    window,
    document,
    'https://static.hotjar.com/c/hotjar-',
    '.js?sv=',
  );

  googleTagManagerLoad(window, document, 'script', 'dataLayer', 'GTM-M5NCKQS');
};

const handleMouseMove = () => {
  loadAnalyticsScripts();
  window.removeEventListener('mousemove', handleMouseMove);
  window.removeEventListener('touchstart', handleMouseMove);
};

export const onInitialClientRender = () => {
  if (process.env.NODE_ENV === 'production') {
    window.addEventListener('mousemove', handleMouseMove);
    window.addEventListener('touchstart', handleMouseMove);
  }
};

export const wrapPageElement: GatsbySSR['wrapPageElement'] = ({ element }) => {
  return <>{element}</>;
};

export const shouldUpdateScroll: GatsbyBrowser['shouldUpdateScroll'] = () => {
  setTimeout(() => {
    window.scrollTo(0, 0);
  }, 0);

  return false;
};
